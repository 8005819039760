body, html{
  height: 100%;
}

.info-box {
   box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);
   border-radius: .25rem;
   background-color: #fff;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   margin-bottom: 1rem;
   min-height: 80px;
   padding: .5rem;
   position: relative;
   width: 100%;
}

.info-box .info-box-icon {
   border-radius: .25rem;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   font-size: 1.875rem;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   text-align: center;
   width: 70px;
}

.info-box .info-box-content {
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-flex-direction: column;
   -ms-flex-direction: column;
   flex-direction: column;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   line-height: 1.8;
   -webkit-flex: 1;
   -ms-flex: 1;
   flex: 1;
   padding: 0 10px;
}

.info-box .info-box-text, .info-box .progress-description {
   display: block;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.info-box .info-box-number {
   display: block;
   margin-top: .25rem;
   font-weight: 700;
}

#plainNav{
  background: #ffffff;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

#plainNav .navbar-nav .nav-item .nav-link.active{
  color: #FF6600  !important;
}


.vicontainer {
  top: 0;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 60%; /* 1:1 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 80%;
  border: none;
}

.vicontainer-video-tabs-sidebar-close{

  float: right;
  position: absolute;
  right: 0;
  padding-top: 30%;

}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #FF6600 !important;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#top{
  font-size: 65px !important;
}

.about-title{
  font-size: 65px !important;
  line-height: 70px;
}

.react-date-picker__wrapper{
  border: none !important;
}