#sidebar{
    width: 320px;
    background: #fff;
    position: relative;
    left: -320px;
    z-index: 10000;
    transition: all 1s;
    border-right: thin solid #d5d5d5;
}

.sidebar-nav-link-item:not(:first-child){
    margin-top: 10px;
}
  
.sidebar-nav-link{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: #000;
    padding: 10px 0 10px 10px;
    display: flex;
    text-decoration: none;
    align-items: center;
}

.sidebar-nav-link.active, 
.sidebar-nav-link:hover{
    background-color: #ffc000;
    color: #fff;
}

.sidebar-nav-link span{
    margin-left: 10px;
}

#sidebar.open{
    left: 0;
}

#navbar{
    background-color: #fff;
    padding: 5px 20px; 
    border-bottom: thin solid #d5d5d5;
}

@media screen and (min-width: 768px) {
    #main{
        margin-left: 320px;
    }

    #sidebar{
        left: 0;
    }
}